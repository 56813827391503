// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeNew from './components/HomeNew';
import AuthComponent from './components/LandingPage';
import AdmitDownload from './components/AdmitDownload';
import FormDetails from './components/FormDetails';
import CenterWise from './components/centerwise';

function App() {
  const [auth, setAuth] = useState({ user: "" });

  return (
    <Router>
      <Routes>
        <Route path="/admit-details/:applicationNumber" element={<FormDetails />} />
        <Route path='/' element={<CenterWise setAuth={setAuth} />} />
		    <Route path='/login' element={<AuthComponent setAuth={setAuth} />} />
        <Route path='/downloadAdmit' element={<AdmitDownload />} />
        <Route path='/home' element={<HomeNew />} />
      </Routes>
    </Router>
  );
}

export default App;
