// src/components/AuthComponent.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap styles
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { API_URL } from "../apiURL";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';
import FAQPage from './FAQ';
import './landing.css'
var FormData = require('form-data');

const CenterWise = () => {
  const navigate = useNavigate();
  const [isLogin, setLogin] = useState(true);
  const [page, setPage] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [captchaAnswer, setCaptchaAnswer] = useState('');
  const [userAnswer, setUserAnswer] = useState('');
  const [captchaImage, setCaptchaImage] = useState('');

  const handleLoginClick = () => {
    setLogin(true);
    setPage(0);
  };

  useEffect(() => {
  }, []);

  const centers = [
    { name: '1st MLP Bn., Mawiong, Shillong', text: 'Will be available for download after Dec 16', enabled: false },
    { name: '2nd MLP Bn., Goeragre, Tura', text: 'Will be available for download after Dec 16', enabled: false },
    { name: '3rd MLP Bn., Sahbsein, Jowai', text: 'Click here for admit card', enabled: true },
    { name: '4th MLP Bn., Sophian, Nongstoin', text: 'Will be available for download after Dec 16', enabled: false },
    { name: '5th MLP Bn., Samanda, Williamnagar', text: 'Will be available for download after Dec 16', enabled: false },
  ];


  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' , padding: '15px'}}>
        <img src="/logo_0.png" alt="Header Image" style={{ height: '100px' }} />
      </div>
      <img src="/menu-bg.png" alt="Header Image" style={{ width: '100%', height: 'auto' }} />
      <Navbar variant="dark" style={{ backgroundColor: '#4c7c7c'}}>
        <Container>
          <Navbar.Brand href="/">Meghalaya Police Recruitment 2024</Navbar.Brand>
          <Nav className="me-auto">
            {//<Nav.Link onClick={handleLoginClick}>Login</Nav.Link>
            //<Nav.Link onClick={handleFAQClick}>FAQ</Nav.Link>
            }
            <Nav.Link href="https://megpolice.gov.in/sites/default/files/advertisement.pdf" > Advertisement </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', backgroundColor: '#dee0d7', padding: '50px', height: 'auto' }}>
      {/* Centers Section */}
      <div style={{ marginTop: '2rem', textAlign: 'center' }}>
        <h3>Centers for Physical Efficiency Test(PET):</h3>
        <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', gap: '1rem', marginTop: '1rem' }}>
          {/* Center Blocks */}
          {centers.map((center, index) => (
            <div
              key={index}
              onClick={() => center.enabled && navigate('/login')}
              style={{
                border: '1px solid #000',
                borderRadius: '8px',
                backgroundColor: center.enabled ? '#fff' : '#f0f0f0',
                maxWidth: '15rem',
                width: '100%',
                padding: '1rem',
                textAlign: 'center',
                cursor: center.enabled ? 'pointer' : 'not-allowed',
                boxShadow: center.enabled ? '0 4px 8px rgba(0,0,0,0.1)' : 'none',
                transition: 'transform 0.2s',
              }}
              onMouseEnter={(e) => center.enabled && (e.currentTarget.style.transform = 'scale(1.05)')}
              onMouseLeave={(e) => center.enabled && (e.currentTarget.style.transform = 'scale(1)')}
            >
              <h5 style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>{center.name}</h5>
              <p style={{ color: '#555' }}>{center.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
    {
    <ToastContainer
    position="bottom-center"
    autoClose={2000}
    hideProgressBar={true}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="light"
    />
    }
      </div>
    );
};

export default CenterWise;
